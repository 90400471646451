<template>
    <!-- container -->
    <div class="main my-2">
        <!-- header -->
        <div class="header m-2">
            <p>header</p>
        </div>
        <!-- body -->
        <div class="body m-2">
            <!-- left -->
            <div class="body-containers my-2 ml-2 mr-1">
                <draggable
                    :list="list1"
                    :group="{name:'my-group', pull: 'clone', put: false}"
                    class="draggable-container"
                    ghost-class="draggable-ghost"
                    handle=".element"
                    :sort="false"
                >
                    <div class="draggable-items" v-for="element in list1" :key="element.name">
                        <div class="element bg-secondary m-1 border">
                            {{element.id}}: {{element.name}}
                        </div>
                    </div>
                </draggable>
            </div>
            <!-- right -->
            <div class="body-containers my-2 ml-1 mr-2">
                <draggable
                    :list="list2"
                    group="my-group"
                    class="draggable-container"
                    ghost-class="draggable-ghost"
                    handle=".element"
                >
                    <div class="draggable-items" v-for="element in list2" :key="element.name">
                        <div class="element bg-secondary m-1 border">
                            {{element.id}}: {{element.name}}
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <!-- footer -->
        <div class="footer m-2">
            <p>footer</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Testing",
    components: {},
    props: {},
    data() {
        return {
            list1: [
                {id: 1, name: 'aaa'},
                {id: 2, name: 'bbb'},
                {id: 3, name: 'ccc'},
                {id: 4, name: 'ddd'},
                {id: 5, name: 'eee'},
                {id: 6, name: 'ananas'},
                {id: 7, name: 'hruška'},
                {id: 8, name: 'jabolka'},
                {id: 9, name: 'breskev'},
                {id: 10, name: 'sliva'},
            ],
            list2: [],
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.main {
    background: silver;
    height: 700px;
    display: flex;
    flex-direction: column;
}

.header {
    background: lightblue;
}

.body {
    background: lightgreen;
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
}

.body-containers {
    background: lightgray;
    width: 50%;
    display: flex;
}

.draggable-container {
    background: whitesmoke;
    flex: 1 0 auto;
    max-width: 100%;
}

.draggable-items {
    display: inline-block;
}

.element {
    cursor: move;
    border-radius: 5px;
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-ghost {
    width: 155px;
    height: 80px;
    display: inline-block;
    opacity: 50%;
}

.footer {
    background: lightsalmon;
}
</style>
